import React from "react"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import dedicated_development_teams from "../../../assets/images/blog/nearshore/dedicated_development_teams-min.jpg"
import top_talent from "../../../assets/images/blog/nearshore/top_talent-min.jpg"
import technologies from "../../../assets/images/blog/nearshore/technologies-min.jpg"

export default () => 
<div>
    <SEO title={'How to Succeed in Personalised Nearshore Development'} 
    description="At ICON Worldwide we've made of personalised nearshore development our main mantra. Ready to discover how we did it? Just keep reading!"
    canonical={'https://icon-worldwide.com/blog/successful-personalised-nearshore-development'}/>

    <NewsHeader/>

    <div id="single-news">
        <h1><span>How to Succeed</span> in Personalised Nearshore Development (and why you should opt for it!)</h1>
        <div id="title-underline"></div>
        <h2>How we’re meeting the demand for development talent.</h2>
        <img src={dedicated_development_teams} style={{width:'100%', margin:'0 auto'}} alt="ICON Worldwide nearshore development dedicated teams" title="ICON Worldwide dedicated teams, Nearshore Development"/>
        <div id="single-news-texts">
            <p>As we’ve grown, like many companies, we have been faced with the shortage of technology talent. As the tech sector continues to flourish, it has become almost impossible to find, afford and retain the programmers you need to run your business. The risk of constant turn-over with developers is huge, causing cost run-ups and project delays. According to LinkedIn the software industry has one of the highest rates of turnover, 13.2%. And a good estimate of the replacement cost is 2x salary. <a  target="_blank" rel="noopener noreferrer" rel="noopener noreferrer" href="https://business.linkedin.com/talent-solutions/blog/trends-and-research/2018/the-3-industries-with-the-highest-turnover-rates">LinkedIn</a></p>
            <h2>Bottom-line, delivering and maintaining digital products is more difficult than ever. </h2>
            <img src={top_talent} style={{width:'100%', margin:'0 auto'}} alt="ICON Worldwide nearshore development top talents" title=" ICON Worldwide top talents, Nearshore Development"/>
            <p>And, many companies are struggling to deliver their projects on-time and maintain margins.</p>
            <p>In 2017, we opened ICON Worldwide in Sofia, Bulgaria to help our existing clients meet this challenge. In Bulgaria, we benefit from access to a tremendous, highly skilled, developer work force. (Why Bulgaria? See these facts: <a target="_blank" rel="noopener noreferrer" rel="noopener noreferrer canonical" href="/blog/icon-bulgaria-nearshore-development">Blog</a>).</p>
            <p>We have the ability to provide clients scaleable teams, across an array of technologies, at a competitive cost point including Drupal, WordPress, Java, Magnolia CMS, <a href="https://www.designrush.com/agency/iphone-app-development-companies" target="_blank" rel="noopener noreferrer">iOS</a> and <a href="https://www.designrush.com/agency/android-app-development-companies" target="_blank" rel="noopener noreferrer">Android</a> mobile development. Project management and UI/UX screen design, traditional print and branding, is still managed locally in Zurich, NY, Madrid or Bahrain.</p>
            <h2>We have built a reliable, consistent team who are deeply integrated in our clients businesses on a long-term basis. </h2>
            <img src={technologies} style={{width:'100%', margin:'0 auto'}} alt="ICON Worldwide nearshore development technologies" tilte="ICON Worldwide technologies, Nearshore Development"/>
            <p>Different from many nearshore operations where the developers change across projects, we have been able to maintain the consistency so critical to building excellent software products. Constant turn-over is one of the leading causes of code-bloat, which in-turn leads to poor website performance. As all businesses continue to become more and more dependent on their developers, building strang relationships is increasingly critical and something we put a lot of emphasis on. We really aim to make the “Nearshore” as close as possible!</p>
        </div>

        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

        <div id="nearshore-images">
            <p>Download our development capabilities: <a  rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://www.dropbox.com/s/wjzx8s4j9xyk9p1/ICON_Dev_1709.pdf">Capabilities</a>.</p>
        </div>

        <NewsFooter previous="sofia-nearshore-development-team" next="software-development-life-cycle-steps"/>
    </div>

    <Footer noScrollbar="true"/>
</div>